import { SceneData } from '../../atoms/scene';
import scenes_scenario_42 from './customerexperience/ce1scenes';

import scenes_scenario_10 from './feedback/sf1scenes';
import scenes_scenario_11 from './feedback/sf2Scenes';
import scenes_scenario_12 from './feedback/sf3Scenes';
import scenes_scenario_20 from './jet/sj1scenes';
import scenes_scenario_21 from './jet/sj2scenes';
import scenes_scenario_22 from './jet/sj3Scenes';
import scenes_scenario_23 from './jet/sj4scenes';
import scenes_scenario_28 from './jet/sj5scenes';
import scenes_scenario_29 from './jet/sj6Scenes';
import scenes_scenario_30 from './vitality/sv1scenes';
import scenes_scenario_31 from './vitality/sv2Scenes';
import scenes_scenario_32 from './vitality/sv3Scenes';
import scenes_scenario_53 from './basicsofsales/bos3scenes';
import scenes_scenario_43 from './customerexperience/ce2scenes';
import scenes_scenario_55 from './basicsofsales/bos1scenes';
import scenes_scenario_44 from './customerexperience/ce3scenes';
import scenes_scenario_56 from './basicsofsales/bos2scenes';
import scenes_scenario_57 from './feedback-retail/fr1scenes';
import scenes_scenario_14 from './tumi/st1scenes';
import scenes_scenario_68 from './feedback-retail/fr2scenes';
import scenes_scenario_69 from './leadership/sl1scenes';
import scenes_scenario_71 from './dsm-personal-data/scenes.ts';


export const sceneList : {[scenario:string]: {[scene:string]: SceneData}} = {
	//sf1
	'10': scenes_scenario_10,
	//sf2
	'11': scenes_scenario_11,
	//sf3
	'12': scenes_scenario_12,

	'14': scenes_scenario_14,

	//ss1
	'17': {},
	//ss2
	'18': {},
	//ss3
	'19': {},

	//sj1
	'20' : scenes_scenario_20,
	//sj2
	'21' : scenes_scenario_21,
	//sj3
	'22' : scenes_scenario_22,
	//sj4
	'23' : scenes_scenario_23,
	//sj5
	'28' : scenes_scenario_28,
	//sj6
	'29' : scenes_scenario_29,
	
	//sv1
	'30' : scenes_scenario_30,
	//sv2
	'31' : scenes_scenario_31,
	//sv3
	'32' : scenes_scenario_32,

	//ce1
	'42' : scenes_scenario_42,
	//ce2
	'43' : scenes_scenario_43,
	//ce2
	'44' : scenes_scenario_44,

	//bos1
	'55' : scenes_scenario_55,
	//bos2
	'56' : scenes_scenario_56,
	//bos3
	'53' : scenes_scenario_53,


	//fr1
	'57': scenes_scenario_57,
	//fr2
	'68': scenes_scenario_68,


	//sl1
	'69': scenes_scenario_69,

	//dsm-personal-data
	'71': scenes_scenario_71

};