import { Vector3 } from "three";
import { SceneData } from "../../../atoms/scene";

const scenes_scenario_71: { [key: string]: SceneData } = {
	'scene1': {
		camera_settings: {
			position: new Vector3(-7.654461575230385, 1.1940128839697128, 0.20927770359882558),
			target: new Vector3(-7.626248944283985, 1, -1.3192186196365214),
		},
		objects: {

			'5': {
				type: 'character',
				position: new Vector3(-7.626248944283985, -0.425, -1.3192186196365214),
				rotation: [0, 0, 0],
				sitting: true,
				idle: 'idle_sitting_desk',
			},
		},
	},

}

export default scenes_scenario_71;